import { ajax } from "@common/services/ajax";
import { AcquisitionCampaignListResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  AcquisitionCampaignCreatePostDataType,
  AcquisitionCampaignUpdatePatchDataType,
  CampaignPromoDataType,
  CampaignPromoType,
} from "@common/types/campaignTypes";

import {
  acquisitionCampaignUrls,
  campaignPromosUrls,
} from "./campaignPromosUrls";

export const campaignPromosApi = {
  create: (
    campaignPromoData: CampaignPromoDataType
  ): Promise<CampaignPromoType> => {
    return ajax.post<CampaignPromoType, CampaignPromoDataType>(
      campaignPromosUrls.create(),
      campaignPromoData
    );
  },
  list: (): Promise<CampaignPromoType[]> =>
    ajax.get<CampaignPromoType[]>(campaignPromosUrls.list()),
  update: (
    id: string,
    campaignPromoData: CampaignPromoDataType
  ): Promise<CampaignPromoType> => {
    return ajax.put(campaignPromosUrls.update(id), campaignPromoData);
  },
};

export const acquisitionCampaignApi = {
  create: (data: AcquisitionCampaignCreatePostDataType): Promise<void> =>
    ajax.post(acquisitionCampaignUrls.create(), data),
  list: (): Promise<AcquisitionCampaignListResponseType> =>
    ajax.get<AcquisitionCampaignListResponseType>(
      acquisitionCampaignUrls.list()
    ),
  update: (
    id: IdType,
    data: AcquisitionCampaignUpdatePatchDataType
  ): Promise<void> => ajax.put(acquisitionCampaignUrls.update(id), data),
};
