import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as CirclePlus } from "@ops-design-system/icons/CirclePlus.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useCreateCampaignMutation } from "@pricing-data/hooks/mutations/useCreateCampaign.mutation";
import { useCampaignPromos } from "@pricing-data/hooks/queries/useCampaignPromos";
import { useUtilities } from "@pricing-data/hooks/queries/useUtilities";
import { CampaignTypeEnum } from "@pricing-utils/types/campaignTypes";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const FormBody = styled(BoDialogBody)`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(3)};
  }
`;

interface AddCampaignFormValues {
  campaignSlug: string;
  promo: IdType;
  type: CampaignTypeEnum;
  utility: IdType;
}

export const AddCampaignFormValidator =
  generateValidationErrorCollector<AddCampaignFormValues>({
    campaignSlug: [isRequired],
    type: [isRequired],
    utility: [isRequired],
  });

interface AddCampaignFormProps {
  onCancel?: () => void;
  onSuccess?: () => void;
}

const AddCampaignForm = ({ onSuccess, onCancel }: AddCampaignFormProps) => {
  const flash = useRhFlash();
  const createCampaign = useCreateCampaignMutation();

  const utilityQuery = useUtilities();
  const campaignPromoQuery = useCampaignPromos();
  const handleSubmit = (values: AddCampaignFormValues) => {
    const createCampaignParams = {
      campaignSlug: values.campaignSlug,
      promoId: values.promo,
      type: values.type,
      utilityId: values.utility,
    };

    createCampaign.mutate(createCampaignParams, {
      onError: () => {
        flash.error("Error creating campaign");
      },
      onSuccess: () => {
        flash.success("Successfully created new campaign");
        onSuccess?.();
      },
    });
  };

  if (utilityQuery.isPending || campaignPromoQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (utilityQuery.isError) {
    return <Body1>Error fetching utilities</Body1>;
  }

  if (campaignPromoQuery.isError) {
    return <Body1>Error fetching campaign promos</Body1>;
  }

  const campaignTypeOptions = Object.entries(CampaignTypeEnum).map(
    ([key, value]) => {
      return {
        label: key,
        value,
      };
    }
  );

  const campaignPromoOptions =
    campaignPromoQuery.data.map(({ code, id }) => {
      return {
        label: code,
        value: id,
      };
    }) || [];

  const utilityOptions =
    utilityQuery.data.map((utility) => {
      return {
        label: utility.name,
        value: utility.id,
      };
    }) || [];

  return (
    <Form<AddCampaignFormValues>
      onSubmit={handleSubmit}
      validate={AddCampaignFormValidator}
      render={({ handleSubmit: handleFormSubmit, valid, dirty }) => {
        return (
          <form onSubmit={handleFormSubmit}>
            <BoDialogHeader>Add Campaign</BoDialogHeader>

            <FormBody>
              <BoTextField
                name="campaignSlug"
                placeholder="Campaign Slug"
                requiredIndicator
              >
                Campaign Slug
              </BoTextField>

              <BoSelectField
                name="utility"
                options={utilityOptions}
                label="Utility"
                requiredIndicator
              />

              <BoSelectField
                name="promo"
                options={campaignPromoOptions}
                label="Campaign Promo"
              />

              <BoSelectField
                name="type"
                options={campaignTypeOptions}
                label="Type"
                requiredIndicator
              />
            </FormBody>

            <BoDialogButtonFooter
              confirmDisabled={!valid || !dirty || createCampaign.isPending}
              confirmBtnText="Submit"
              confirmBtnType="submit"
              cancelBtnText="Close"
              onCancel={onCancel}
            />
          </form>
        );
      }}
    />
  );
};

export const AddCampaign = () => {
  const { open, close, isOpen } = useModalState();

  return (
    <>
      <BoButton
        icon={CirclePlus}
        onClick={open}
        variant="secondary"
        size="extraSmall"
      >
        New Campaign
      </BoButton>
      {isOpen ? (
        <BoModal open onClose={close}>
          <AddCampaignForm onSuccess={close} onCancel={close} />
        </BoModal>
      ) : null}
    </>
  );
};
