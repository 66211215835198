import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  isPositiveNumeric,
  isRequired,
  isValidDateTodayOrFuture,
} from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { RhError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { BoTextInput } from "@ops-design-system/components/BoTextInput/BoTextInput";
import { ReactComponent as CirclePlus } from "@ops-design-system/icons/CirclePlus.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { pricingApi } from "@pricing-data/api/pricingApi";
import React, { PropsWithChildren } from "react";
import { Form, useFormState } from "react-final-form";
import styled from "styled-components";

interface AddOfferPromoProps {
  onSuccess: () => void;
}

const validator = generateValidationErrorCollector<AddOfferPromoFormValues>({
  code: [isRequired],
  expirationDate: [isValidDateTodayOrFuture],
  futureValue: [isPositiveNumeric],
  value: [isRequired, isPositiveNumeric],
});

interface AddOfferPromoFormValues {
  code: string;
  expirationDate: string;
  futureValue: string;
  value: string;
}

const EditorDialogBody = styled(BoDialogBody)`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(3)};
  }
`;

interface PresentationValueFieldProps {
  name: string;
  placeholder: string;
}

const PresentationValueField = ({
  name,
  placeholder,
  children,
}: PropsWithChildren<PresentationValueFieldProps>) => {
  const { values } = useFormState<AddOfferPromoFormValues>({
    subscription: { values: true },
  });
  const value = Number(values.value) + Number(values.futureValue);

  return (
    <BoTextInput
      name={name}
      placeholder={placeholder}
      readOnly
      disabled
      value={value}
    >
      {children}
    </BoTextInput>
  );
};

export const AddOfferPromo = ({ onSuccess }: AddOfferPromoProps) => {
  const flash = useRhFlash();
  const { open, close, isOpen } = useModalState();
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();

  const handleSubmit = (values: AddOfferPromoFormValues) => {
    setPending();

    const newOfferPromo = {
      code: values.code,
      expirationDate: values.expirationDate,
      futureValue: Number(values.futureValue),
      value: Number(values.value),
    };

    pricingApi.offerPromos
      .create(newOfferPromo)
      .then(() => {
        setSuccess();
        flash.success("Successfully created new offer promo");
        onSuccess();
      })
      .catch((error: RhError) => {
        setFailure(error);
        flash.error(`Failed to create new offer promo: ${error.data.errors}`);
      });
  };

  return (
    <>
      <BoButton variant="secondary" icon={CirclePlus} onClick={open}>
        New Offer Promo
      </BoButton>
      <BoModal open={isOpen} onClose={close}>
        <Form<AddOfferPromoFormValues>
          onSubmit={handleSubmit}
          validate={validator}
          initialValues={{
            futureValue: "0",
            value: "0",
          }}
          render={({ handleSubmit: handleFormSubmit, valid, dirty }) => {
            return (
              <form onSubmit={handleFormSubmit}>
                <BoDialogHeader>Add Offer Promo</BoDialogHeader>
                <EditorDialogBody>
                  <BoTextField name="code">Code</BoTextField>
                  <BoTextField name="value" type="number">
                    Initial Value
                  </BoTextField>
                  <BoTextField name="futureValue" type="number">
                    Future Value
                  </BoTextField>
                  <PresentationValueField
                    name="presentationValue"
                    placeholder="Presentation Value"
                  >
                    Presentation Value
                  </PresentationValueField>
                  <BoDateField
                    name="expirationDate"
                    label="Expiration Date"
                    placeholder="mm/dd/yyyy"
                  />
                </EditorDialogBody>
                <BoDialogButtonFooter
                  confirmDisabled={!valid || !dirty || requestMonitor.isPending}
                  confirmBtnText="Submit"
                  confirmBtnType="submit"
                  cancelBtnText="Close"
                  onCancel={close}
                />
              </form>
            );
          }}
        />
      </BoModal>
    </>
  );
};
