import { EnvTypes } from "@common/enums/envTypes.enum";

export interface EnvSettings {
  adminUrl: string;
  axiosBaseUrl: string;
  axiosTimeoutMs: number;
  oktaClientId: string;
  oktaIssuer: string;
  oktaRedirectUri: string;
  sentryDSN: string;
  sentryRelease: string;
  sentryTracesSampleRate: number;
}

export const allEnvApplicationSettings: Record<EnvTypes, EnvSettings> = {
  local: {
    adminUrl: "http://localhost:8080/admin",
    axiosBaseUrl: "http://localhost:8080",
    axiosTimeoutMs: 60000,
    oktaClientId: "0oa1w8ehdNwvzG6t71d6",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "http://localhost:3002/implicit/callback",
    sentryDSN: "",
    sentryRelease: "",
    sentryTracesSampleRate: 0,
  },
  localStaging: {
    adminUrl: "https://api.staging.gotrhythm.com/admin",
    axiosBaseUrl: "https://api.staging.gotrhythm.com",
    axiosTimeoutMs: 40000,
    oktaClientId: "0oa1w8ehdNwvzG6t71d6",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "http://localhost:3002/implicit/callback",
    sentryDSN: "",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.5,
  },
  production: {
    adminUrl: "https://api.gotrhythm.com/admin",
    axiosBaseUrl: "https://api.gotrhythm.com",
    axiosTimeoutMs: 40000,
    oktaClientId: "0oa17m61eclKYz81D4x7",
    oktaIssuer: "https://login.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://pricing.gotrhythm.com/implicit/callback",
    sentryDSN:
      "https://10a8df1009e6419c9e31cdf6bf25b670@o415325.ingest.sentry.io/5465236",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.2,
  },
  staging: {
    adminUrl: "https://api.staging.gotrhythm.com/admin",
    axiosBaseUrl: "https://api.staging.gotrhythm.com",
    axiosTimeoutMs: 40000,
    oktaClientId: "0oa25s89oxy1uN9pt1d6",
    oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
    oktaRedirectUri: "https://pricing.staging.gotrhythm.com/implicit/callback",
    sentryDSN:
      "https://10a8df1009e6419c9e31cdf6bf25b670@o415325.ingest.sentry.io/5465236",
    sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
    sentryTracesSampleRate: 0.5,
  },
};
