import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { AuthClient } from "@common/services/AuthClient.service";
import { RhApiError } from "@common/types/errorTypes";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { OKTA_CONFIG } from "@pricing/settings/config";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 10vh;
  text-align: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-top: 24px;
  width: 300px;
`;

export interface SignInFormValues {
  email: string;
  password: string;
}

const initialValues = Object.freeze<SignInFormValues>({
  email: "",
  password: "",
});

const signInFormValidator = generateValidationErrorCollector<SignInFormValues>({
  email: [isRequired, isValidEmail],
});

const authClient = AuthClient.getInstance(OKTA_CONFIG);

const ERROR_LOGGING_IN = "Error logging in";

export const SignInPage = () => {
  const flash = useRhFlash();

  const onSubmit = ({ email, password }: SignInFormValues) => {
    return authClient.signIn(email, password).catch((error: RhApiError) => ({
      email: [ERROR_LOGGING_IN],
      password: [ERROR_LOGGING_IN],
    }));
  };

  return (
    <Container>
      <RhTypography variant="h1">Rhythm Pricing</RhTypography>
      <Form<SignInFormValues>
        onSubmit={onSubmit}
        initialValues={{ ...initialValues }}
        validate={signInFormValidator}
        render={({ handleSubmit }) => (
          <StyledForm
            onSubmit={handleSubmit}
            data-testid="signInForm"
            noValidate
          >
            <RhTextField name="email" autoComplete="email" autoFocus>
              Email
            </RhTextField>
            <RhTextField name="password" type="password">
              Password
            </RhTextField>
            <RhSubmitButton
              data-tracking-click={{ event: "" }}
              onError={(errors) => {
                flash.error(JSON.stringify(errors));
              }}
            >
              Login
            </RhSubmitButton>
          </StyledForm>
        )}
      />
    </Container>
  );
};
