import { BO_TABLE_CORNER_RADIUS } from "@ops-design-system/components/BoTable/BoTable.constants";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { borders } from "@ops-design-system/styles/borders";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

type Alignments = "left" | "right" | "center";

export const BoTableLabel = styled(Body2).attrs({ as: "h4" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const BoTableCaption = styled(BoTableLabel).attrs({ as: "caption" })`
  text-align: left;
`;

export const BoTable = styled.table`
  border: none;
  border-spacing: 0;
  padding: 0;
  & tr:last-child td:first-child {
    border-bottom-left-radius: ${BO_TABLE_CORNER_RADIUS};
  }
  & tr:last-child td:last-child {
    border-bottom-right-radius: ${BO_TABLE_CORNER_RADIUS};
  }
  & tr:first-child th:first-child {
    border-top-left-radius: ${BO_TABLE_CORNER_RADIUS};
  }
  & tr:first-child th:last-child {
    border-top-right-radius: ${BO_TABLE_CORNER_RADIUS};
  }
`;
export const BoTableHead = styled.thead``;
export const BoTableRow = styled.tr`
  &:nth-of-type(even) {
    background-color: ${grey["50"]};
  }
`;
export const BoTableCell = styled(Body2).attrs({ as: "td" })<{
  $align?: Alignments;
}>`
  border-bottom: ${borders["100"]};
  border-left: ${borders["100"]};
  border-spacing: 0;
  color: ${grey["900"]};
  padding: ${rhOpsSpacingPx(0.5)} ${rhOpsSpacingPx(2)};
  text-align: ${({ $align }) => $align || "left"};
  &:last-child {
    border-right: ${borders["100"]};
  }
`;

// Sizes table cell to width of contents instead of default table cell widths
export const BoTableCellContentWidth = styled(BoTableCell)`
  white-space: nowrap;
  width: 1%;
`;

export const BoTableBody = styled.tbody``;
export const BoTableHeadCell = styled(Body3).attrs({
  as: "th",
})<{ $align?: Alignments }>`
  border-bottom: ${borders["100"]};
  border-spacing: 0;
  border-top: ${borders["100"]};

  color: ${grey["600"]};
  font-weight: ${FontWeight.SemiBold};
  padding: ${rhOpsSpacingPx(0.5)} ${rhOpsSpacingPx(2)};
  text-align: ${({ $align }) => $align || "center"};
  &:first-child {
    border-left: ${borders["100"]};
  }
  &:last-child {
    border-right: ${borders["100"]};
  }
`;
