import { campaignPromosApi } from "@common/api/campaignPromosApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  isAllCaps,
  isRequired,
  isValidOneOrBothRequired,
} from "@common/forms/validators";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { CampaignPromoType } from "@pricing-utils/types/campaignTypes";
import { DateField } from "@pricing/components/DateField/DateField";
import { toDecimalValue } from "@pricing/formatters/number.formatter";
import dayjs from "dayjs";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

export interface CampaignPromoFormValues {
  code: string;
  expirationDate: string;
  oneTimeValue: string;
  recurringValue: string;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(1)};
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const CampaignPromoFormValidator =
  generateValidationErrorCollector<CampaignPromoFormValues>({
    code: [isRequired, isAllCaps],
    oneTimeValue: [
      (value, { recurringValue }) =>
        isValidOneOrBothRequired(value, recurringValue),
    ],
    recurringValue: [
      (value, { oneTimeValue }) =>
        isValidOneOrBothRequired(value, oneTimeValue),
    ],
  });

interface CampaignPromoFormProps {
  handleSuccess: (campaignPromo: CampaignPromoType) => void;
}

export const AddCampaignPromoForm = ({
  handleSuccess,
}: CampaignPromoFormProps) => {
  const flash = useRhFlash();

  const onSubmit = ({
    code,
    oneTimeValue,
    recurringValue,
    expirationDate,
  }: CampaignPromoFormValues) => {
    campaignPromosApi
      .create({ code, expirationDate, oneTimeValue, recurringValue })
      .then(handleSuccess)
      .catch(() => {
        flash.error("Error creating campaign promo. Please try again.");
      });
  };

  return (
    <Form<CampaignPromoFormValues>
      onSubmit={onSubmit}
      validate={CampaignPromoFormValidator}
      render={({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Container>
            <RhTextField name="code" placeholder="Promo Code">
              Code
            </RhTextField>

            <RhTextField
              name="oneTimeValue"
              placeholder="0.00"
              parse={(value) => toDecimalValue(value, 2)}
            >
              One Time Value
            </RhTextField>

            <RhTextField
              name="recurringValue"
              placeholder="0.000"
              parse={(value) => toDecimalValue(value, 3)}
            >
              Recurring Value
            </RhTextField>
            <DateField
              data-testid="AddCampaignPromoForm__expirationDateField"
              name="expirationDate"
              label="Reward Bonus Deadline"
              minDate={dayjs().add(1, "day")}
            />
            <RhButton
              data-tracking-click={{ event: "" }}
              type="submit"
              color="primary"
            >
              Submit
            </RhButton>
          </Container>
        </form>
      )}
    />
  );
};
