import {
  SelectThemes,
  SelectVariants,
} from "@ops-design-system/components/BoSelectInput/BoSelect.styled";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { sanitizeValue } from "@ops-design-system/utils/selectHelpers";
import React from "react";
import Select, { Options, Theme } from "react-select";

export type BoSelectValue = string | number | null;

export interface BoSelectOptionType {
  label: JSX.Element | string;
  value: BoSelectValue;
}

export type BoOptionsType = Options<BoSelectOptionType>;

export interface BoSelectProps {
  ariaLabel?: string;
  ariaLabelledby?: string;
  defaultValue?: BoSelectValue;
  disabled?: boolean;
  hasErrors?: boolean;
  id?: string;
  onBlur?: () => void;
  onChange?: (option: BoSelectOptionType | null) => void;
  onFocus?: () => void;
  options: BoOptionsType;
  placeholder?: string;
  value?: BoSelectValue;
  variant?: SelectVariants.Primary | SelectVariants.Borderless;
}

export const BoSelectInput = ({
  id,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  defaultValue: givenDefaultValue,
  ariaLabel,
  ariaLabelledby,
  disabled,
  hasErrors,
  value: givenValue,
  variant = SelectVariants.Primary,
}: BoSelectProps) => {
  const { styles, components } = SelectThemes[variant];
  const value = sanitizeValue(givenValue, options);
  const defaultValue = sanitizeValue(givenDefaultValue, options);

  return (
    <Select
      inputId={id}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      components={components}
      isDisabled={disabled}
      isSearchable={false}
      theme={(theme: Theme) => {
        return {
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: darkPurple["100"],
            primary25: darkPurple["50"],
            primary50: darkPurple["50"],
          },
        };
      }}
      styles={styles(hasErrors)}
      options={options}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      defaultValue={defaultValue}
      placeholder={placeholder}
      value={value}
      menuPlacement="auto"
      menuPosition="fixed"
    />
  );
};
