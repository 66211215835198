import { IdType } from "@common/types/apiTypes";

const CAMPAIGN_PROMOS_BASE_URL = "/api/pricing/campaign_promos";
const ACQUISITION_CAMPAIGNS_BASE_URL = "/api/pricing/acquisition_campaigns";

export const campaignPromosUrls = {
  create: () => `${CAMPAIGN_PROMOS_BASE_URL}/`,
  list: () => `${CAMPAIGN_PROMOS_BASE_URL}/`,
  retrieve: (id: string) => `${CAMPAIGN_PROMOS_BASE_URL}/${id}/`,
  update: (id: string) => `${CAMPAIGN_PROMOS_BASE_URL}/${id}/`,
};

export const acquisitionCampaignUrls = {
  create: () => `${ACQUISITION_CAMPAIGNS_BASE_URL}/`,
  list: () => `${ACQUISITION_CAMPAIGNS_BASE_URL}/`,
  update: (id: IdType) => `${ACQUISITION_CAMPAIGNS_BASE_URL}/${id}/`,
};
